.editHeader {
  composes: textWhite from "style";
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 43px;
  background-color: var(--mb-color-bg-white-alpha-15);
}

.editHeaderButtons {
  margin-left: auto;
}

.editHeaderButton {
  border: none;
  color: var(--mb-color-brand);
}

.saveButton {
  composes: editHeaderButton;
}

.cancelButton {
  composes: editHeaderButton;
  opacity: 0.5;
}
.editHeader {
  composes: textWhite from "style";
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 43px;
  background-color: var(--mb-color-bg-white-alpha-15);
}

.editHeaderButtons {
  margin-left: auto;
}

.editHeaderButton {
  border: none;
  color: var(--mb-color-brand);
}

.saveButton {
  composes: editHeaderButton;
}

.cancelButton {
  composes: editHeaderButton;
  opacity: 0.5;
}
