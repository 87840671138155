.preview {
  address,
  article,
  aside,
  blockquote,
  canvas,
  dd,
  div,
  dl,
  dt,
  fieldset,
  figcaption,
  figure,
  footer,
  form,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  header,
  hr,
  li,
  main,
  nav,
  noscript,
  ol,
  p,
  pre,
  section,
  table,
  tfoot,
  ul,
  video {
    /**
     * The truncation detection only works when all the elements in the markdown preview
     * are inline. Because we cannot control the types of elements being rendered, we force them all to
     * inline here.
     */
    display: inline-block;
  }
}

.preview.oneLine {
  white-space: normal;
}
.preview {
  address,
  article,
  aside,
  blockquote,
  canvas,
  dd,
  div,
  dl,
  dt,
  fieldset,
  figcaption,
  figure,
  footer,
  form,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  header,
  hr,
  li,
  main,
  nav,
  noscript,
  ol,
  p,
  pre,
  section,
  table,
  tfoot,
  ul,
  video {
    /**
     * The truncation detection only works when all the elements in the markdown preview
     * are inline. Because we cannot control the types of elements being rendered, we force them all to
     * inline here.
     */
    display: inline-block;
  }
}

.preview.oneLine {
  white-space: normal;
}
