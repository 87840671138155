.AlertModalsBorder {
  border-width: 2px;
  border-color: var(--mb-color-border);
}

.AlertModalsTextWidth {
  width: 162px;
}
.AlertModalsBorder {
  border-width: 2px;
  border-color: var(--mb-color-border);
}

.AlertModalsTextWidth {
  width: 162px;
}
