.floatLeft {
  float: left;
}

.floatRight {
  float: right;
}
.floatLeft {
  float: left;
}

.floatRight {
  float: right;
}
