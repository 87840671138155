.Table {
  width: 100%;
  border-collapse: unset;
  border-spacing: 0;
  margin-block: 1rem;
  position: relative;
  border-radius: 0.5rem;
  border: 1px solid var(--mb-color-border);

  th {
    text-align: left;
    padding: 0.5rem;
    border-bottom: 1px solid var(--mb-color-border);
    padding-inline: 0.75rem;
  }

  tbody {
    width: 100%;
    max-height: 600px;
    overflow-y: auto;
  }

  tbody > tr:hover {
    background-color: var(--mb-color-brand-lighter);
  }

  td {
    border-bottom: 1px solid var(--mb-color-border);
    padding-inline: 0.75rem;
  }
}
.Table {
  width: 100%;
  border-collapse: unset;
  border-spacing: 0;
  margin-block: 1rem;
  position: relative;
  border-radius: 0.5rem;
  border: 1px solid var(--mb-color-border);

  th {
    text-align: left;
    padding: 0.5rem;
    border-bottom: 1px solid var(--mb-color-border);
    padding-inline: 0.75rem;
  }

  tbody {
    width: 100%;
    max-height: 600px;
    overflow-y: auto;
  }

  tbody > tr:hover {
    background-color: var(--mb-color-brand-lighter);
  }

  td {
    border-bottom: 1px solid var(--mb-color-border);
    padding-inline: 0.75rem;
  }
}
