.usefulQuestions {
  composes: mt1 from "style";
  color: var(--mb-color-brand);
  font-size: 16px;
}
.usefulQuestions {
  composes: mt1 from "style";
  color: var(--mb-color-brand);
  font-size: 16px;
}
