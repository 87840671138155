.shortcutSelected {
  color: var(--mb-color-text-selected);
  background-color: var(--mb-color-background-selected);
  border-color: var(--mb-color-background-selected);
}

.shortcut:hover {
  color: var(--mb-color-text-hover);
  background-color: var(--mb-color-background-hover);
  border-color: var(--mb-color-background-hover);
}

.sectionLine {
  padding: 0;
  border: 0;
  border-top: 1px solid var(--mb-color-border);
  margin-bottom: 0.5rem;
}

.sectionLabel {
  padding: 0.3rem 1rem;
  margin: auto;
  font-size: 0.625rem;
  font-weight: 700;
}
.shortcutSelected {
  color: var(--mb-color-text-selected);
  background-color: var(--mb-color-background-selected);
  border-color: var(--mb-color-background-selected);
}

.shortcut:hover {
  color: var(--mb-color-text-hover);
  background-color: var(--mb-color-background-hover);
  border-color: var(--mb-color-background-hover);
}

.sectionLine {
  padding: 0;
  border: 0;
  border-top: 1px solid var(--mb-color-border);
  margin-bottom: 0.5rem;
}

.sectionLabel {
  padding: 0.3rem 1rem;
  margin: auto;
  font-size: 0.625rem;
  font-weight: 700;
}
