:root {
  --title-color: var(--mb-color-text-medium);
}

.headerBody {
  composes: textDark textBold from "style";
  flex: 1 0 auto;
  display: flex;
  overflow: hidden;
  align-items: center;
  border-color: var(--mb-color-border);
}

.headerTextInput {
  composes: p1 from "style";
  font-size: 18px;
  color: var(--title-color);
  max-width: 550px;
}

.headerSchema {
  composes: textLight from "style";
  position: absolute;
  top: -10px;
  font-size: 12px;
}
:root {
  --title-color: var(--mb-color-text-medium);
}

.headerBody {
  composes: textDark textBold from "style";
  flex: 1 0 auto;
  display: flex;
  overflow: hidden;
  align-items: center;
  border-color: var(--mb-color-border);
}

.headerTextInput {
  composes: p1 from "style";
  font-size: 18px;
  color: var(--title-color);
  max-width: 550px;
}

.headerSchema {
  composes: textLight from "style";
  position: absolute;
  top: -10px;
  font-size: 12px;
}
