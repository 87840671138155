.wFull {
  width: 100%;
}

.ParameterDropdownWidth {
  width: 18.75rem;
}
.wFull {
  width: 100%;
}

.ParameterDropdownWidth {
  width: 18.75rem;
}
