.colorIcon {
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 3px;
}

.icon {
  composes: transitionColor from "style";
  color: currentColor;
}
.colorIcon {
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 3px;
}

.icon {
  composes: transitionColor from "style";
  color: currentColor;
}
