.BrandCircle {
  background-color: var(--mb-color-brand);
  color: var(--mb-color-text-white);
  border-radius: 50%;
  height: 1rem;
  width: 1rem;
  padding: 1px;
}

.IconMargin {
  margin-top: 0.25rem;
}
.BrandCircle {
  background-color: var(--mb-color-brand);
  color: var(--mb-color-text-white);
  border-radius: 50%;
  height: 1rem;
  width: 1rem;
  padding: 1px;
}

.IconMargin {
  margin-top: 0.25rem;
}
