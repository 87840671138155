.CopyButton {
  &:focus {
    outline: 2px solid var(--mb-color-focus);
  }
}
.CopyButton {
  &:focus {
    outline: 2px solid var(--mb-color-focus);
  }
}
